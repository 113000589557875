import React, { ReactElement } from 'react';
import { Container, createTheme, ThemeProvider } from '@mui/material';
import { HashRouter, Location, Navigate, Route, Routes } from 'react-router-dom';
import BestPracticesViewer from './bestPractices/BestPracticesViewer';
import Login from './login/Login';
import { UserModel } from '../models/UserModel';
import ProtectedRoute from '../components/ProtectedRoute';
import NavigationDrawer, { NavLink } from '../components/NavigationDrawer/NavigationDrawer';
import { observer } from 'mobx-react-lite';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { Config, DefaultAuthRoute, RouterLinks } from '../util/Config';
import Account from './account/Account';
import Register from './register/Register';
import GroupsIcon from '@mui/icons-material/Groups';
import InfoIcon from '@mui/icons-material/Info';
import RemoteContentPage from '../components/RemoteContentPage/RemoteContentPage';
import useWindowDimensions from '../components/WindowDimensions';
import { Nullable } from '../util/Util';
import { VideoPlayerPage } from './videoPlayer/VideoPlayerPage';
import ForgotPassword from './forgotPassword/ForgotPassword';
import ResetPassword from './resetPassword/ResetPassword';
import { ArticleOutlined, HelpOutline, PolicyOutlined } from '@mui/icons-material';
import ComputerIcon from '@mui/icons-material/Computer';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import { HttpRequest } from '../util/HttpRequest';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LinkIcon from '@mui/icons-material/Link';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

const theme = createTheme({
  palette: {
    primary: {
      main: '#176423'
    },
    secondary: {
      main: '#1abb5c'
    },
    background: {
      default: '#ffffff'
    }
  }
});

const baseLinks = {
  bestPractices: {
    title: 'Best Practices',
    link: RouterLinks.bestPractices,
    icon: <WorkspacePremiumIcon/>
  },
  professional: {
    title: 'Professionals',
    link: RouterLinks.professionals,
    icon: <GroupsIcon/>
  },
  npvSaas: {
    title: <span>NP&sup3;V SaaS</span>,
    link: 'NPV_SAAS',
    unnavigable: true,
    icon: <ComputerIcon/>,
    children: [{
      title: 'ESG Score',
      link: 'ESGScore',
      unnavigable: true,
      icon: <LocationCityIcon/>,
      tooltip: 'Coming soon'
    }]
  },
  propTech: {
    title: 'PropTech',
    link: 'PropTech',
    unnavigable: true,
    icon: <AssuredWorkloadIcon/>,
    tooltip: 'Coming soon'
  },
  accountLinks: {
    title: 'Account',
    link: RouterLinks.account,
    icon: <AccountCircleIcon/>,
    children: [{
      title: 'Terms and Conditions',
      link: Config.links.termsAndConditions,
      external: true,
      icon: <ArticleOutlined/>
    }, {
      title: 'Privacy Policy',
      link: Config.links.privacyPolicy,
      external: true,
      icon: <PolicyOutlined/>
    },]
  },
  logout: (user: UserModel) => ({
    title: 'Logout',
    icon: <LogoutIcon/>,
    unnavigable: true,
    click: user.logout,
    link: ''
  })

}

const App = observer(() => {

  const [user] = React.useState<UserModel>(new UserModel());
  const [openState, setOpenState] = React.useState(false);
  const [howToUrl, setHowToUrl] = React.useState("");
  const [videoOnboardingUrl, setVideoOnboardingUrl] = React.useState("");
  const [videoOnboardingTitle, setVideoOnboardingTitle] = React.useState("");
  const [links, setLinks] = React.useState<NavLink[][]>([
    [baseLinks.bestPractices, baseLinks.professional], [baseLinks.accountLinks, baseLinks.logout(user)], [baseLinks.npvSaas, baseLinks.propTech]])
  const [redirectLocation, setRedirectLocation] = React.useState<Nullable<Location>>(null);
  const { width, height } = useWindowDimensions();
  const wideScreen = width / height > 1;

  React.useEffect(() => {
    HttpRequest.GET('app?fields[0]=urls')
      .then(r => r.json())
      .then(r => {
        const config = r['data']['attributes']['urls'];

        const onboardingKeys = [
          { title: 'Investors', configKey: 'investors' },
          { title: 'Developers', configKey: 'developers' },
          { title: 'Designers', configKey: 'designers' },
          { title: 'City Planners', configKey: 'city_planners' },
          { title: 'Contractors', configKey: 'contractors' },
          { title: 'Manufacturers', configKey: 'manufacturers' },
          { title: 'Tenant Businesses', configKey: 'tenant_businesses' },
          { title: 'School Boards', configKey: 'school_boards' },
          { title: 'Inhabitants', configKey: 'inhabitants' },
          { title: 'Activist / Actionists', configKey: 'activist_slash_actionists' },
        ]

        setHowToUrl(config.howToVideoUrl);
        setLinks([[
          baseLinks.bestPractices,
          baseLinks.professional,
          {
            title: 'About',
            link: RouterLinks.about,
            icon: <InfoIcon/>,
            children: [{
              title: "How To Use",
              link: RouterLinks.howTo,
              icon: <HelpOutline/>
            }, {
              title: "Methodologies",
              link: config.methodologiesUrl,
              icon: <LinkIcon/>,
              external: true
            }, {
              title: "Definitions",
              link: config.definitionsUrl,
              icon: <LinkIcon/>,
              external: true
            }, {
              title: "Onboarding",
              icon: <AssignmentIndIcon/>,
              link: 'onboarding-menu',
              unnavigable: true,
              children: onboardingKeys.map(keyset => ({
                title: keyset.title, icon: <OndemandVideoIcon/>, link: RouterLinks.videoOnboarding, unnavigable: !config.onboarding[keyset.configKey], click: () => {
                  setVideoOnboardingTitle(keyset.title + " Onboarding")
                  setVideoOnboardingUrl(config.onboarding[keyset.configKey])
                }
              }))
            }]
          },
        ], [baseLinks.accountLinks, baseLinks.logout(user)], [baseLinks.npvSaas, baseLinks.propTech]])
      })
  }, [user.logout])

  const UnauthenticatedRoute = (el: ReactElement, nav: string) => user.isAuthenticated() ? <Navigate to={ nav }/> : el;

  return (
    <ThemeProvider theme={ theme }>
      <Container component="main">
        <HashRouter>
          { user.isAuthenticated() && <NavigationDrawer links={ links } user={ user } width={ 250 } permanent={ wideScreen } openState={ openState } setOpenState={ setOpenState }/> }
          <div style={ user.isAuthenticated() && wideScreen ? { width: 'calc(100vw - 250px)', left: 250, position: 'absolute', minHeight: '100%' } : {} }>
            <Routes>
              {/* Protected Routes */ }
              <Route path={ RouterLinks.bestPractices } element={
                <ProtectedRoute redirectLocation={ redirectLocation } setRedirectLocation={ setRedirectLocation } user={ user } authUrl={ DefaultAuthRoute }><BestPracticesViewer stickyNav={ wideScreen } user={ user }/></ProtectedRoute> }></Route>
              <Route path={ RouterLinks.account } element={ <ProtectedRoute redirectLocation={ redirectLocation } setRedirectLocation={ setRedirectLocation } user={ user } authUrl={ DefaultAuthRoute }><Account user={ user }/></ProtectedRoute> }></Route>
              <Route path={ RouterLinks.professionals } element={
                <ProtectedRoute redirectLocation={ redirectLocation } setRedirectLocation={ setRedirectLocation } user={ user } authUrl={ DefaultAuthRoute }><RemoteContentPage backupTitle={ 'Professionals' } url={ 'professional' }/></ProtectedRoute> }></Route>
              <Route path={ RouterLinks.about } element={
                <ProtectedRoute redirectLocation={ redirectLocation } setRedirectLocation={ setRedirectLocation } user={ user } authUrl={ DefaultAuthRoute }><RemoteContentPage backupTitle={ 'About' } url={ 'about' }/></ProtectedRoute> }></Route>
              <Route path={ RouterLinks.howTo } element={
                <ProtectedRoute redirectLocation={ redirectLocation } setRedirectLocation={ setRedirectLocation } user={ user } authUrl={ DefaultAuthRoute }><VideoPlayerPage videoUrl={ howToUrl } title={ "How To Use P3I Analytics" }/></ProtectedRoute> }></Route>
              <Route path={ RouterLinks.videoOnboarding } element={
                <ProtectedRoute redirectLocation={ redirectLocation } setRedirectLocation={ setRedirectLocation } user={ user } authUrl={ DefaultAuthRoute }><VideoPlayerPage videoUrl={ videoOnboardingUrl } title={ videoOnboardingTitle }/></ProtectedRoute> }></Route>

              {/* Unprotected Routes */ }
              {/*<Route path={ RouterLinks.onboarding } element={ UnauthenticatedRoute(<OnBoarding/>, RouterLinks.bestPractices) }></Route>*/ }
              <Route path={ RouterLinks.login } element={ UnauthenticatedRoute(<Login user={ user }/>, RouterLinks.bestPractices) }></Route>
              <Route path={ RouterLinks.register } element={ UnauthenticatedRoute(<Register user={ user }/>, RouterLinks.bestPractices) }></Route>
              <Route path={ RouterLinks.forgotPassword } element={ UnauthenticatedRoute(<ForgotPassword user={ user }/>, RouterLinks.bestPractices) }></Route>
              <Route path={ RouterLinks.resetPassword } element={ UnauthenticatedRoute(<ResetPassword user={ user }/>, RouterLinks.bestPractices) }></Route>

              {/* Default Route */ }
              <Route path='*' element={ <Navigate to={ user && user.userRole ? RouterLinks.bestPractices : RouterLinks.account }/> }></Route>
            </Routes>
          </div>
        </HashRouter>
      </Container>
    </ThemeProvider>
  );
});

export default App;
