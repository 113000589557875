import * as React from 'react';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Collapse, SwipeableDrawer, Typography } from '@mui/material';
import { UserModel } from '../../models/UserModel';
import { useLocation, useNavigate } from 'react-router-dom';
import { Config } from '../../util/Config';
import styles from './NavigationDrawer.module.scss';
import Grid2 from '@mui/material/Unstable_Grid2';
import mcmaccx_logo from '../../assets/mcmaccx_logo.jpg'
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useEffect } from 'react';
import { HttpRequest } from '../../util/HttpRequest';
import { ResponseObject } from '../../util/Util';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export interface NavLink {
    tooltip?: string;
    title: string | JSX.Element;
    icon: JSX.Element;
    link: string;
    click?: () => void
    external?: boolean
    children?: NavLink[]
    unnavigable?: boolean
}

interface Props {
    openState: boolean;
    setOpenState: (s: boolean) => void;
    width: number;
    permanent: boolean;
    user: UserModel;
    links: NavLink[][]
}

interface Partner {
    title: string;
    link: string;
    imageUrl: string;
}

export default function NavigationDrawer({ openState, setOpenState, width, permanent, links }: Props) {

    const navigate = useNavigate();
    const location = useLocation();
    const [partnersIndex, setPartnersIndex] = React.useState(0);
    const [partners, setPartners] = React.useState<Partner[]>([]);
    const [subMenuOpen, setSubMenuOpen] = React.useState('');

    useEffect(() => {
        HttpRequest.GET('partners?populate=*')
            .then(r => r.json())
            .then(result => {
                const data: Partner[] = [];
                result['data'].forEach((e: ResponseObject) => {
                    data.push({
                        title: e['attributes']['title'],
                        link: e['attributes']['link'],
                        imageUrl: e['attributes']['image']['data']['attributes']['url']
                    })
                });
                setPartners(data);
            })
    }, []);

    const toggleDrawer = (open: boolean) => () => {
        setOpenState(open);
    };

    const openSubMenu = (key: string) => {
        setSubMenuOpen(key);
    }

    const doNavigate = (nav: NavLink) => {
        if (nav.external) window.open(nav.link)
        else navigate(nav.link)
    }

    const navSection = (section: Props["links"][0], level: number, menuRoot: string = "") => section.map((item, j) => <React.Fragment key={ item.link + j }>
        <ListItem key={ item.link + j } disablePadding>
            <ListItemButton key={ j } onClick={ () => {
                // Click takes precedence
                if (item.click) item.click()

                // Then navigation
                if (!item.unnavigable) doNavigate(item);

                // Then open/close submenu
                if (item.children && !subMenuOpen.includes(item.link)) openSubMenu(`${ menuRoot }#${ item.link }`)
                else if (item.children && (item.unnavigable || location.pathname.includes(item.link))) openSubMenu(subMenuOpen.replace(item.link, ""))
            } }>
                <ListItemIcon sx={ { minWidth: 0 } } className={ styles.navIcon }>{ item.icon }</ListItemIcon>
                <ListItemText primary={ item.title }/>
                { item.children ? subMenuOpen.includes(item.link) ? <ExpandLess/> : <ExpandMore/> : <></> }
            </ListItemButton>
        </ListItem>
        { item.children && <>
          <Collapse in={ subMenuOpen.includes(item.link) } timeout="auto" unmountOnExit>
            <List sx={ { pl: level } }>
                { navSection(item.children, level + 1, item.link) }
            </List>
          </Collapse>
        </> }
    </React.Fragment>)

    const list = () => (
        <Box
            sx={ { width } }
            className={ styles.navDrawer }
            role="presentation"
            onClick={ toggleDrawer(false) }
            onKeyDown={ toggleDrawer(false) }
        >
            <Grid2 container
                   direction={ 'column' }
                   className={ styles.navGrid }>
                <Grid2 flex={1} className={ styles.bgGreen } overflow={'auto'}>
                    <List>
                        { links.length >= 1 && navSection(links[0], 1) }
                    </List>
                    <Divider/>
                    <List>
                        { links.length >= 2 && navSection(links[1], 1) }
                    </List>
                    <Divider/>
                    <List>
                        { links.length >= 3 && navSection(links[2], 1) }
                    </List>
                </Grid2>
                <Grid2 xs={ 12 } textAlign={ 'center' } className={ styles.bgGreen }>
                    <Typography component={ 'div' } color={ '#e1e1e1' } variant={ 'caption' } sx={ { marginBottom: 1 } }>Partnered With</Typography>
                    <AutoPlaySwipeableViews index={ partnersIndex } onChangeIndex={ setPartnersIndex }>
                        { partners.map((p, i) => <Grid2 height={ '100%' } container alignItems={ 'center' } justifyContent={ 'center' } key={ i }>
                            <a rel="noopener noreferrer" target="_blank" href={ p.link }>
                                <img src={ p.imageUrl } className={ styles.partnerImg } alt=""/>
                            </a>
                        </Grid2>) }
                    </AutoPlaySwipeableViews>
                    <Divider sx={ { marginTop: 1, marginBottom: 1 } }/>
                    <Typography component={ 'div' } variant={ 'caption' }>Powered by</Typography>
                    <a href={ Config.links.mcmaccxWebsite } target="_blank" rel="noopener noreferrer">
                        <img src={ mcmaccx_logo } className={ styles.mcmaccxLogo } alt=""/>
                    </a>
                    <Typography component={ 'div' } variant={ 'caption' }>Advanced Analytics</Typography>
                </Grid2>
            </Grid2>
        </Box>
    );

    return (
        <>
            <Button disableRipple style={ { position: 'fixed', top: 10, left: 0, zIndex: 99, color: 'white' } } onClick={ toggleDrawer(true) }><MenuIcon/></Button>
            <SwipeableDrawer
                anchor={ 'left' }
                open={ openState }
                variant={ permanent ? 'permanent' : 'temporary' }
                onOpen={ toggleDrawer(true) }
                onClose={ toggleDrawer(false) }
                style={ { zIndex: 100 } }
            >
                { list() }
            </SwipeableDrawer>
        </>
    );
}
