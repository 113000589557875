import React, { useCallback } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box, Button, Container, CssBaseline, FormHelperText, Grid, Link, TextField, Typography } from '@mui/material';
import { UserModel } from '../../models/UserModel';
import { Config, RouterLinks } from '../../util/Config';
import { useNavigate } from 'react-router-dom';
import classes from './Login.module.scss'

interface Props {
  user: UserModel
}

function Login({ user }: Props) {

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    const data = new FormData(event.currentTarget);
    const { error } = await user.login(data.get('email') as string, data.get('password') as string);
    if (error) {
      setError(error);
      setLoading(false);
    } else navigate(RouterLinks.bestPractices);
  }, [user, navigate]);

  return (
    <Container component="main">
      <CssBaseline/>
      <Box
        sx={ {
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        } }
      >
        <Avatar sx={ { m: 1, bgcolor: 'secondary.main' } }>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={ handleSubmit } noValidate sx={ { mt: 1 } }>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            disabled={ loading }
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            disabled={ loading }
            autoComplete="current-password"
          />
          { !loading && error && <FormHelperText error={ !!error }>{ error }</FormHelperText> }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={ { mt: 3, mb: 2 } }
          >
            Sign In
          </Button>
          <Grid container justifyContent={ 'center' } rowSpacing={ 1 } columnSpacing={ 4 }>
            <Grid item>
              <Link onClick={ () => navigate(RouterLinks.register) } variant="body2">
                { "Don't have an account? Sign Up" }
              </Link>
            </Grid>
            <Grid item>
              <Link onClick={ () => navigate(RouterLinks.forgotPassword) } variant="body2">
                { "Forgot Password?" }
              </Link>
            </Grid>
          </Grid>
          <Grid container justifyContent={ 'center' } rowSpacing={ 1 } columnSpacing={ 4 }>
            <Grid item>
              <Link target={'_blank'} href={Config.links.termsAndConditions} variant="body2">
                { "Terms and Conditions" }
              </Link>
            </Grid>
            <Grid item>
              <Link target={'_blank'} href={Config.links.privacyPolicy} variant="body2">
                { "Privacy Policy" }
              </Link>
            </Grid>
          </Grid>

          <Grid container justifyContent={ 'center' } rowSpacing={ 1 } columnSpacing={ 4 }>
            <img alt={''} className={classes.bgImage} src={ 'https://mcmaccx-storage.nyc3.digitaloceanspaces.com/ANTI_6b46202781.png' }/>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
